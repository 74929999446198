// import crypto from "../util/crypto";
import auth from "../util/auth";
import Vue from "vue";
import axios from 'axios';
import pubUtil from '@/util/public'
import router from '@/router'

export default {

  /**
   * @param config:https://github.com/axios/axios#request-config
   * @param setting:{hideLoading: true/false隱藏loading, hideFailMessage: true/false 隱藏失敗彈窗}
   */
  fetch(config = {}, setting = {}) {
    const reqSetting = {
      hideLoading: false,
      hideMessage: false,
      ...setting
    }
    let loader;
    const instance = axios.create({
      baseURL: process.env.VUE_APP_API_HOST_V3,
      ...config,
      headers: {
        "Accept": "application/json, text/plain, */*",
        "Access-Control-Allow-Origin": "*",
        "authorization": "Bearer "+auth.getAdminToken(),
        ...config.headers
      }

    });
    // Add a request interceptor
    instance.interceptors.request.use(function (config) {
      // Do something before request is sent
      reqSetting.hideLoading || (loader = Vue.$loading.show());
      return config;
    }, function (error) {
      // Do something with request error
      loader?.hide();
      reqSetting.hideFailMessage || pubUtil.showNotify(error.response?.data.msg || error);
      return Promise.reject(error);
    });

    // Add a response interceptor
    instance.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      loader?.hide();
      return response.data;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      loader?.hide();
      if (error.response.status === 401) {
        pubUtil.showNotify("認證過期，請重新登入");
        auth.clearToken();
        router.push({ name: "index" });

      } else {
        reqSetting.hideFailMessage || pubUtil.showNotify(error.response?.data.msg || error);
      }
      return Promise.reject(error);
    });

    return instance.request(config)

  },
};
