<template>
    <div class="bg">
        <sideBar :pageName="pagename" activeSection="202211"></sideBar>
        <div class="content">
            <div class="title">全球首都大挑戰 伊將陪你遊全球</div>
            <div class="subTitle">參與名單</div>
            <div class="labelGroup">
                <p class="inputLabel" style="width: 15vw">UserToken查詢</p>
                <p class="signLabel">完成保戶簽到</p>
                <p class="dateLabel">日期區間</p>
            </div>
            <div></div>
            <div class="inputGroup">
                <input type="text" placeholder="UserToken查詢" v-model="filterToken" style="width: 15vw">
                <select v-model="filterProtect">
                    <option :value="item.value" v-for="(item, index) in protectedOption" :key="index">{{ item.label }}
                    </option>
                </select>
                <date-picker v-model="time" valueType="YYYY-MM-DD" range></date-picker>
                <button class="searchBtn" @click="setupData">查詢</button>
                <button class="downloadBtn" @click="download">下載</button>
            </div>
            <div class="row" v-if="dataList.length > 0">
                <table class="rwd-table member">
                    <tr>
                        <th>User Token</th>
                        <th>LINE暱稱</th>
                        <th>抽獎次數</th>
                        <th>明信片寄出次數</th>
                        <th>是否加入好友</th>
                        <th>成功邀請好友數</th>
                        <th>保戶簽到時間</th>
                        <th>User建立時間</th>
                    </tr>
                    <tr v-for="(item, index) in dataList" :key="index">
                        <td data-th="User Token">{{ item.userToken }}</td>
                        <td data-th="LINE暱稱">{{ item.nickname }}</td>
                        <td data-th="抽獎次數">{{ item.countLottery }}</td>
                        <td data-th="明信片寄出次數">{{ item.countCard }}</td>
                        <td data-th="是否加入好友">{{ Number(item.isFriend) === 1 ? '是' : '否' }}</td>
                        <td data-th="成功邀請好友數">{{ item.countShareFriend }}</td>
                        <td data-th="保戶簽到時間">{{ item.insuranceMissionCompleteAt }}</td>
                        <td data-th="User建立時間">{{ item.createdAt }}</td>
                    </tr>
                </table>

                <!--頁碼-->
                <div class="page overflow-auto mt-2 mb-2">
                    <div style="text-align: center;">
                        <el-pagination
                                @current-change="handleCurrentChange"
                                :page-size=pageSize
                                layout="prev, pager, next, jumper"
                                :total=totalCount
                                :current-page.sync=currentPage
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import sideBar from '../../components/side-bar'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import userApi from "@/apis/userV3";

export default {
    name: 'participate',
    components: {
        sideBar,
        DatePicker
    },
    data() {
        return {
            pagename: '202211參與名單',
            time: null,
            pageSize: 15,
            totalCount: 0,
            currentPage: 1,
            dataList: [],
            filterToken: "",
            filterProtect: '',
            protectedOption: [
                {label: "全部", value: ''},
                {label: "是", value: 1},
                {label: "否", value: 0},
            ]
        }
    },
    created() {
        this.setupData()
    },
    methods: {
        handleCurrentChange(val) {
            this.currentPage = val
            this.setupData()
        },
        setupData() {
            let data = {
                start_date: "",
                end_date: "",
                is_protected: this.filterProtect,
                user_token: this.filterToken,
                offset: this.currentPage,
                page_size: this.pageSize,
            }
            if (this.time) {
                data["start_date"] = this.time[0]
                data["end_date"] = this.time[1]
            }
            userApi.getUserList(data).then((json) => {
                console.log(json.data)
                this.totalCount = json.data.totalCount
                this.dataList = json.data.dataList
            });
        },
        download() {
            let data = {
              is_protected: this.filterProtect
            }
            if (this.filterToken) {
                data["user_token"] = this.filterToken
            }
            if (this.time) {
                data["start_date"] = this.time[0]
                data["end_date"] = this.time[1]
            }
            userApi.exportUserList(data)
        }
    }
}
</script>

<style lang="scss" scoped>
.bg {
    min-height: 100vh;
    width: 100vw;
    display: inline-flex;
}

table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

td, th {
    border: 1px solid #ddd;
    padding: 8px;
}

table tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tr:hover {
    background-color: #ddd;
}

th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #27327b;
    color: white;
}

.content {
    margin: 55px;
    width: calc(100vw - 260px);

    .title {
        border-left: 10px solid #5386c0;
        padding-left: 20px;
        font-size: 36px;
        font-weight: bold;
    }

    .subTitle {
        margin-top: 20px;
        font-size: 24px;
        font-width: 500;
        color: #6d6c6c;
    }

    .labelGroup {
        margin-top: 20px;
        display: inline-flex;

        p {
            margin-bottom: 5px;
            font-size: 8px;
        }

        .inputLabel {
            width: 150px;
        }

        .signLabel {
            width: 100px;
            margin-left: 20px;
        }

        .dateLabel {
            margin-left: 10px;
        }
    }

    .inputGroup {
        display: inline-flex;

        button {
            margin-left: 10px;
            color: #fff;
            width: auto;
            min-width: 80px;
            border-radius: 5px;
            cursor: pointer;
        }

        input {
            width: 150px;
            border: 1px solid #c1c1c1;
            border-radius: 5px;
            margin-right: 10px;
            padding-left: 5px;
        }

        select {
            width: 100px;
            border: 1px solid #c1c1c1;
            border-radius: 5px;
            margin-right: 10px;
            padding-left: 5px;
        }

        .searchBtn {
            background-color: #5386c0;
        }

        .downloadBtn {
            background-color: #5386c0;
        }
    }
}

.mt-2{
  margin-top: 16px;
}
.mb-2{
  margin-bottom: 16px;
}
</style>
