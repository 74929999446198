import axios from "@/util/axiosV2";

export default {
    getUserList(data) {
        return axios.fetch({
            method: "post",
            url: "v1/dashboard/user-list",
            data
        });
    },
    exportUserList(data) {
        const queryString = new URLSearchParams(data).toString();
        location.href = process.env.VUE_APP_API_HOST_V2+"v1/dashboard/exports/user-list?"+queryString
    },
}
